/** @jsx jsx */
import { graphql } from "gatsby"
import { jsx, Container } from "theme-ui"
import {
  Layout,
  SEO,
  BannerContent,
  ImageContent,
  QuoteBox,
  TitleGridList,
} from "../components"

import GetCashOfferForm from "../forms/get-cash-offer"

const IndexPage = ({ data }) => {
  const {
    title,
    bannerTitle,
    bannerDescription,
    bannerImage,
    mainContentImage,
    mainContentDescription,
    mainContentBox,
    gridListTitle,
    gridList,
  } = data.contentfulHomeTemplate

  return (
    <Layout>
      <SEO title={title} />
      <div sx={{ bg: "background" }}>
        <Container
          px={3}
          py={[4, 5, 100]}
          sx={{
            display: "grid",
            gridTemplateColumns: ["1fr", "1fr", "2fr 1fr"],
            gridGap: 4,
          }}
        >
          <BannerContent
            title={bannerTitle}
            description={bannerDescription}
            fluid={bannerImage.fluid}
          />
          <GetCashOfferForm />
        </Container>
      </div>
      <div sx={{ bg: "gray2" }}>
        <Container px={3} py={[4, 5, 100]}>
          <ImageContent
            fluid={mainContentImage.fluid}
            richText={mainContentDescription}
            sx={{ mb: [4, 5, 100] }}
          />
          <QuoteBox richText={mainContentBox} />
        </Container>
      </div>
      <div sx={{ bg: "background" }}>
        <Container px={3} py={[4, 5, 100]}>
          <TitleGridList title={gridListTitle} list={gridList} />
        </Container>
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    contentfulHomeTemplate(title: { eq: "Home" }) {
      id
      title
      bannerTitle
      bannerDescription
      bannerImage {
        ...CustomContentfulFluid
      }
      mainContentImage {
        ...CustomContentfulFluid
      }
      mainContentDescription {
        raw
      }
      mainContentBox {
        raw
      }
      gridListTitle
      gridList {
        title
        description
        icon {
          ...CustomContentfulFluid
        }
      }
    }
  }
`

export default IndexPage
